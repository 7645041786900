import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { useMediaQuery } from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { AntTab, AntTabs } from "../../atleta/styles/PerfilPessoa";
import { useStyles } from "../styles/TabelaAtleta";
import _ from "lodash";
import TableAtletaResultado from "./TableAtletaResultado";

export default function TabelaAtleta(props) {
  const { eventoProva, classesProvas, evento } = props;
  const classes = useStyles();
  const [provaParams, setProvaParams] = useState({
    idProva: null,
    faseAtual: "f",
    tabIndexFormato: 0,
    tabIndexClasse: 0,
    tabIndexGenero: 0,
    tabIndexProva: 0,
    evento: evento,
  });

  const handleChangeTabFormato = (event, newValue) => {
    setProvaParams({
      ...provaParams,
      faseAtual: "f",
      tabIndexFormato: newValue,
      tabIndexGenero: 0,
      tabIndexClasse: 0,
      tabIndexProva: 0,
    });
  };
  const handleChangeTabGenero = (event, newValue) => {
    setProvaParams({
      ...provaParams,
      tabIndexGenero: newValue,
      faseAtual: "f",
      tabIndexClasse: 0,
      tabIndexProva: 0,
    });
  };
  const handleChangeTabClasse = (event, newValue) => {
    setProvaParams({
      ...provaParams,
      tabIndexClasse: newValue,
      faseAtual: "f",
      tabIndexProva: 0,
    });
  };
  const handleChangeTabProva = (event, newValue) => {
    setProvaParams({
      ...provaParams,
      faseAtual: "f",
      tabIndexProva: newValue,
    });
  };

  const handleChangeFase = (event, newValue) => {
    setProvaParams({ ...provaParams, faseAtual: newValue });
  };

  const nomeProva = (prova) => {
    if (prova) {
      let fase = "";
      if (provaParams.faseAtual === "f") {
        fase = "Final";
      }
      if (provaParams.faseAtual === "e") {
        fase = "Eliminatória";
      }
      if (provaParams.faseAtual === "s") {
        fase = "Semifinal";
      }
      if (prova.aparelho.descricao === "PLATAFORMA") {
        return `${fase} ${prova.sexo === "F" ? "Feminino" : "Masculino"} ${
          prova.aparelho.descricao
        } ${prova.classe.descricao}`;
      }
      return `${fase} ${prova.sexo === "F" ? "Feminino" : "Masculino"} ${
        prova.aparelho.descricao
      } ${prova.classe.descricao}`;
    }
    return "";
  };

  const nomeProvaOption = (prova) => {
    if (
      prova.aparelho.descricao === "PLATAFORMA" ||
      prova.aparelho.descricao === "SINCRONIZADO PLATAFORMA"
    ) {
      return prova.aparelho.descricao;
    } else {
      return `${prova.aparelho.descricao} ${prova.altura.descricao}`;
    }
  };

  const classesOptions = useMemo(() => {
    let tipoAparelho = ["I", "E"];
    let provas = [];
    if (classesProvas[0]) {
      provas = _.filter(classesProvas, function (o) {
        return o.aparelho === tipoAparelho[provaParams.tabIndexFormato];
      });

      if (!provas.length) {
        provas = classesProvas;
      }
    } else {
      provas = classesProvas;
    }

    //ordenação categorias
    if (provas.length) {
      return _.orderBy(provas, ["classe.descricao"], ["asc"]);
    }
    return provas;
  }, [classesProvas, provaParams.tabIndexFormato]);

  const provas = useMemo(() => {
    let genero = ["F", "M"];
    let tipoAparelho = ["I", "E"];
    if (
      eventoProva.payload &&
      classesOptions[0] &&
      classesOptions[provaParams.tabIndexClasse]
    ) {
      const provasFiltradas = _.filter(eventoProva.payload, function (o) {
        return (
          o.aparelho.tipo_aparelho ===
            tipoAparelho[provaParams.tabIndexFormato] &&
          o.sexo === genero[provaParams.tabIndexGenero] &&
          o.id_classe === classesOptions[provaParams.tabIndexClasse].id
        );
      });
      //ordenação provas
      if (provasFiltradas.length) {
        const provasOrder = _.orderBy(
          provasFiltradas,
          ["aparelho.created_at", "altura.descricao"],
          ["asc", "asc"]
        );
        return provasOrder;
      }
    }
    return [];
  }, [
    eventoProva,
    provaParams.tabIndexClasse,
    provaParams.tabIndexGenero,
    provaParams.tabIndexFormato,
    classesOptions,
  ]);

  useEffect(() => {
    if (provas[0]) {
      setProvaParams((provaParams) => ({
        ...provaParams,
        idProva: provas[provaParams.tabIndexProva].id,
      }));
    }
  }, [provas, provaParams.tabIndexProva]);

  const matchesMD = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <div className={classes.root}>
      <Container maxWidth="md" className={classes.containerMD}>
        <Typography className={classes.title}>Placar Detalhado</Typography>
        <Typography variant="body2" className={classes.titleCaption}>
          Encontre a categoria que busca através dos filtros abaixo:
        </Typography>

        <Typography className={classes.tituloFiltro}>Formato</Typography>
        <AntTabs
          value={provaParams.tabIndexFormato}
          onChange={handleChangeTabFormato}
          className="centered"
        >
          <AntTab label="Individual" />
          <AntTab label="Sicronizado" />
        </AntTabs>

        <Typography className={classes.tituloFiltro}>Gênero</Typography>
        <AntTabs
          value={provaParams.tabIndexGenero}
          onChange={handleChangeTabGenero}
          className="centered"
        >
          <AntTab label="Feminino" />
          <AntTab label="Masculino" />
        </AntTabs>

        {classesOptions[0] && (
          <React.Fragment>
            <Typography className={classes.tituloFiltro}>Categoria</Typography>
            <AntTabs
              variant={
                classesOptions.length > 3 || !matchesMD
                  ? "scrollable"
                  : "standard"
              }
              value={provaParams.tabIndexClasse}
              onChange={handleChangeTabClasse}
              className="centered"
              orientation={
                provas.length > 3 || !matchesMD ? "vertical" : "horizontal"
              }
            >
              {classesOptions.map((item, key) => (
                <AntTab
                  label={
                    item.classe.sigla
                      ? item.classe.sigla
                      : item.classe.descricao
                  }
                  key={key}
                />
              ))}
            </AntTabs>
          </React.Fragment>
        )}
        {provas[0] && (
          <div className={classes.filtroProvas}>
            <Typography className={classes.tituloFiltro}>Prova</Typography>
            <AntTabs
              variant={
                classesOptions.length > 3 || !matchesMD
                  ? "scrollable"
                  : "standard"
              }
              value={provaParams.tabIndexProva}
              onChange={handleChangeTabProva}
              className="centered"
              orientation={
                provas.length > 3 || !matchesMD ? "vertical" : "horizontal"
              }
            >
              {provas.map((item, key) => (
                <AntTab label={nomeProvaOption(item)} key={key} />
              ))}
            </AntTabs>
          </div>
        )}
      </Container>
      <div className={classes.cardTabelaWrapper}>
        <Paper className={classes.cardTabela}>
          {provas.length > 0 && provas[provaParams.tabIndexProva] && (
            <React.Fragment>
              <Typography className={classes.tableTitle}>
                {nomeProva(provas[provaParams.tabIndexProva])}
              </Typography>
              <AntTabs
                value={provaParams.faseAtual}
                onChange={handleChangeFase}
                centered
              >
                {provas[provaParams.tabIndexProva].id_etapa_e && (
                  <AntTab value="e" label="Eliminatória" />
                )}
                {provas[provaParams.tabIndexProva].id_etapa_s && (
                  <AntTab value="s" label="Semi Final" />
                )}
                {provas[provaParams.tabIndexProva].id_etapa_f && (
                  <AntTab value="f" label="Final" />
                )}
              </AntTabs>
            </React.Fragment>
          )}

          {evento && provaParams.idProva ? (
            <TableAtletaResultado provaParams={provaParams} />
          ) : null}
        </Paper>
      </div>
    </div>
  );
}
